import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  productList: [],
  productDetails: null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
};

// List Product
export const listProductsActionCreator = createAsyncThunk(
  "product",
  async () => {
    const API_URL = `${SERVER_URL.PRODUCT_LIST}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const createProductActionCreator = createAsyncThunk(
  "product/create",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.PRODUCT_LIST}`;
    try {
      const { data } = await fetchUtility("post", API_URL, params.body, true, {
        successToast: true,
        errorToast: false,
      });
      return data;
    } catch (error) {
      const errors = {
        rate_year: error.response?.data?.errors?.rate_year?.[0] || null,
        product: error.response?.data?.errors?.product_name?.[0] || null,
        description: error.response?.data?.errors?.description?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateProductActionCreator = createAsyncThunk(
  "product/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.BULK_UPDATE_PRODUCT}`;
      const { data } = await fetchUtility(
        "patch",
        API_URL,
        [params.body],
        true,
        params.toast
          ? params.toast
          : { successToast: false, errorToast: false },
      );
      return data;
    } catch (error) {
      const errorResponse = error.response?.data.errors[0].errors[0];

      if (typeof errorResponse === "string") {
        throw new Error(errorResponse);
      }

      const errors = {
        rate_year: errorResponse?.rate_year || null,
        product: errorResponse?.product_name || null,
        description: errorResponse?.description || null,
      };

      return rejectWithValue(errors);
    }
  },
);

export const listRateYearActionCreator = createAsyncThunk(
  "product/rate-years/",
  async (params) => {
    const API_URL = `${SERVER_URL.RATE_YEAR_LIST}${params.id}/`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const exportProductsActionCreator = createAsyncThunk(
  "product/export/",
  async () => {
    const API_URL = `${SERVER_URL.EXPORT_PRODUCTS}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: true,
      errorToast: true,
    });
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "products.csv"; // Name of the file to be downloaded
    link.click();
    return data;
  },
);

export const deleteProductAction = createAsyncThunk(
  "product/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.PRODUCT_LIST}`;
    const { data } = await fetchUtility(
      "delete",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: true },
    );
    return data;
  },
);

export const bulkCopyRateYears = createAsyncThunk(
  "product/copy",
  async (params, { rejectWithValue }) => {
    try {
      let API_URL = `${SERVER_URL.PRODUCT_COPY_RATEYEARS}`;
      const { data } = await fetchUtility(
        "post",
        `${API_URL}`,
        params.body,
        true,
        {
          successToast: true,
          errorToast: false,
        },
      );
      return data;
    } catch (error) {
      const errorResponse = error.response?.data;
      const errors = {
        years: errorResponse?.errors?.years?.[0] || errorResponse?.message,
      };
      return rejectWithValue(errors);
    }
  },
);

export const getProductsByVendor = createAsyncThunk(
  "product/vendor",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.VENDOR_PRODUCT_LIST}/${params.id}/`;
      const { data } = await fetchUtility("get", API_URL, null, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      const errorResponse = error.response.data?.message;
      const errors = {
        product: errorResponse,
      };
      return rejectWithValue(errors);
    }
  },
);

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setBtnLoaderFalse: (state) => {
      state.btnLoader = false;
    },
  },
  extraReducers: (builder) => {
    // Create Product
    builder.addCase(createProductActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createProductActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(createProductActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    // Update Product
    builder.addCase(updateProductActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateProductActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(updateProductActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    // List Products
    builder.addCase(listProductsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(listProductsActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.productList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(listProductsActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    // Delete Product
    builder.addCase(deleteProductAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteProductAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(deleteProductAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    //Export Products
    builder.addCase(exportProductsActionCreator.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(exportProductsActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(exportProductsActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    //copy rate year
    builder.addCase(bulkCopyRateYears.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(bulkCopyRateYears.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(bulkCopyRateYears.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
    //get products by vendor
    builder.addCase(getProductsByVendor.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getProductsByVendor.fulfilled, (state) => {
      state.btnLoader = false;
    });
    builder.addCase(getProductsByVendor.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
  },
});

export default productSlice.reducer;
export const { setBtnLoaderFalse } = productSlice.actions;
