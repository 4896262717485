// eslint-disable-next-line no-undef
export const BASIC_API_URL = process.env.REACT_APP_API_URL;
const SERVER_URL = {
  LOGIN: `${BASIC_API_URL}/users/login/`,
  SSO_LOGIN: `${BASIC_API_URL}/users/sso/login/`,
  SSO_CALLBACK: `${BASIC_API_URL}/users/sso/callback/`,
  USER_LIST: `${BASIC_API_URL}/users/list/`,
  USER_PROFILE: `${BASIC_API_URL}/users/profile/`,
  CREATE_USER: `${BASIC_API_URL}/users/register/`,
  UPDATE_USER: `${BASIC_API_URL}/users/update/`,
  DELETE_USER: `${BASIC_API_URL}/users/delete/`,
  RESET_PASSWORD: `${BASIC_API_URL}/users/change-password/`,
  MFA_STATUS: `${BASIC_API_URL}/users/mfa/status/`,
  MFA_REGISTER: `${BASIC_API_URL}/users/mfa/setup/`,
  MFA_VERIFY: `${BASIC_API_URL}/users/mfa/verify/`,
  MFA_REMOVE: `${BASIC_API_URL}/users/mfa/disable/`,
  USER_VERIFY: `${BASIC_API_URL}/users/verify-user/`,
  LOGOUT: `${BASIC_API_URL}/users/logout/`,
  UPDATE_PROFILE: `${BASIC_API_URL}/users/profile/update/`,
  REFRESH_TOKEN: `${BASIC_API_URL}/users/token/refresh/`,
  PROPERTY_LIST: `${BASIC_API_URL}/property/list/`,
  CREATE_PROPERTY: `${BASIC_API_URL}/property/create/`,
  UPDATE_PROPERTY: `${BASIC_API_URL}/property/update/`,
  DELETE_PROPERTY: `${BASIC_API_URL}/property/delete/`,
  RETRIEVE_PROPERTY: `${BASIC_API_URL}/property/view`,
  CATEGORY: `${BASIC_API_URL}/category/`,
  CREATE_CATEGORY: `${BASIC_API_URL}/category/create/`,
  VENDOR: `${BASIC_API_URL}/vendors/`,
  CREATE_VENDOR: `${BASIC_API_URL}/vendors/create/`,
  PRODUCT_LIST: `${BASIC_API_URL}/product/`,
  SUB_CATEGORY: `${BASIC_API_URL}/group/`,
  CREATE_SUB_CATEGORY: `${BASIC_API_URL}/group/create/`,
  RATE_YEAR_LIST: `${BASIC_API_URL}/product/rate-years/`,
  BULK_UPDATE_PRODUCT: `${BASIC_API_URL}/product/update/`,
  EXPORT_PRODUCTS: `${BASIC_API_URL}/product/export-products-csv/`,
  BUDGET_LIST: `${BASIC_API_URL}/budget/`,
  CREATE_BUDGET: `${BASIC_API_URL}/budget/create/`,
  BUDGET_CYCLE: `${BASIC_API_URL}/product/product-groups`,
  DELETE_BUDGET: `${BASIC_API_URL}/budget/delete/`,
  UPDATE_BUDGET: `${BASIC_API_URL}/budget/update/`,
  BUDGET_DETAIL: `${BASIC_API_URL}/budget/`,
  EXPORT_BUDGET: `${BASIC_API_URL}/budget/export/csv/`,
  EXPORT_BUDGET_LIST: `${BASIC_API_URL}/budget/export/`,
  EXPORT_BUDGET_DETAIL: `${BASIC_API_URL}/budget/generate-budget-excel/`,
  COMPLETE_BUDGET: `${BASIC_API_URL}/budget/complete-budgeting/`,
  BUDGET_PRODUCTS: `${BASIC_API_URL}/budget/products/filter/`,
  BUDGET_ADD_PRODUCT: `${BASIC_API_URL}/budget/products/add/`,
  EDIT_BUDGET_PRODUCT: `${BASIC_API_URL}/budget/products/update/`,
  DELETE_BUDGET_PRODUCT: `${BASIC_API_URL}/budget/products/delete/`,
  BUDGET_PRODUCT_DETAIL: `${BASIC_API_URL}/budget/product/details/`,
  BUDGET_ALL: `${BASIC_API_URL}/budget/all/`,
  ASSIGN_USER_PROPERTIES: `${BASIC_API_URL}/property/assign-users/`,
  PRODUCT_COPY_RATEYEARS: `${BASIC_API_URL}/product/fixed-products/copy/`,
  VENDOR_PRODUCT_LIST: `${BASIC_API_URL}/product/vendor`,
  PRODUCT_DETAILS_BY_BUDGET_YEAR: `${BASIC_API_URL}/product/add/budget-details/`,
};

export const role = [
  {
    id: 1,
    label: "Admin",
    value: true,
  },
  {
    id: 2,
    label: "Standard",
    value: false,
  },
];
export const status = [
  {
    id: 1,
    label: "Active",
    value: true,
  },
  {
    id: 2,
    label: "Inactive",
    value: false,
  },
];

export const statusType = [
  {
    id: 1,
    label: "Active",
    value: "Active",
  },
  {
    id: 2,
    label: "Inactive",
    value: "Inactive",
  },
];

export const budgetType = [
  {
    id: 1,
    label: "Pending Approval",
    value: "Pending Approval",
  },
  {
    id: 2,
    label: "Working Version",
    value: "Working Version",
  },
  {
    id: 3,
    label: "Approved",
    value: "Approved",
  },
];

export const propertyTypes = [
  // { label: "Commercial", value: "Commercial" },
  // { label: "Residential", value: "Residential" },
  { label: "Living", value: "Living" },
  { label: "Office", value: "Office" },
  { label: "Retail", value: "Retail" },
  { label: "Land", value: "Land" },
  { label: "Campus", value: "Campus" },
  { label: "Industrial", value: "Industrial" },
];

export const propertyStatus = [
  { label: "Active", value: "Active" },
  { label: "Archived", value: "Archived" },
];

export const groupType = [
  { label: "Required", value: "Required" },
  { label: "Optional", value: "Optional" },
];

export const costType = [
  { label: "Variable", value: "Variable" },
  { label: "Fixed", value: "Fixed" },
];

export const rateStatus = [
  { label: "Estimated", value: "Estimated" },
  { label: "Final", value: "Final" },
];

export const frequency = [
  { label: "One Time", value: "One Time" },
  { label: "Recurring", value: "Recurring" },
  { label: "As Needed", value: "As Needed" },
];

export const region = [
  { label: "United States", value: "US" },
  { label: "Canada", value: "CA" },
];
export default SERVER_URL;
