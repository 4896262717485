import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
// import { toast } from "react-toastify";
// import Error from "../../../assets/icons/error.svg";
// import ToastContent from "../../../components/toast/ToastContent";
import Button from "../../../components/button/Button";
import logo from "../../../assets/logo/hines.svg";
import InputFields from "../../../components/inputFields/InputFields";
import { QRCodeSVG } from "qrcode.react";
import style from "./Login.module.scss";
import {
  // getLoginActionCreator,
  getUserDetailActionCreator,
  getSSOLoginActionCreator,
  // getMFAStatusActionCreator,
  // registerMFAActionCreator,
  verifyMFAActionCreator,
  getSSOCallbackActionCreator,
} from "../../../redux/slices/LoginSlices";
import LeftImageBlock from "../../../components/leftImageBlock/LeftImageBlock";
// import OauthPopup from 'react-oauth-popup';

const Login = () => {
  const dispatch = useDispatch();
  // const location = useLocation();
  const [credMessage, setCredMessage] = useState({ status: "", message: "" });
  const [showMFA, setShowMFA] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [mfaError, setMFAError] = useState(false);
  // const loginErr = useSelector((state) => state.login.error);
  // const [showPassword, setShowPassword] = useState(false);
  // const [isPageExpire, setIsPageExpire] = useState(false);
  const isLoading = useSelector((state) => state.login.loading);
  // const [rememberMe, setRememberMe] = useState(false);
  const [otpLockMsg, setOtpLockMsg] = useState(null);
  const [disableMFA, setDisableMFA] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [ssoTimeout, setSsoTimeout] = useState(null);
  const isApiCallInProgress = useRef(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
    reset,
    setError,
    getValues,
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      email: "",
      // password: "",
      mfaCode: "",
    },
  });
  const navigate = useNavigate();

  const sessionTimeout = 15 * 60 * 1000; // 15 minutes in milliseconds

  useEffect(() => {
    setQrCode("");
    const handleMessage = (event) => {
      const appUrl =
        //eslint-disable-next-line no-undef
        process.env.NODE_ENV === "development"
          ? //eslint-disable-next-line no-undef
            process.env.REACT_APP_APP_URL
          : //eslint-disable-next-line no-undef
            process.env.REACT_APP_STAGING_URL;
      if (event.origin !== appUrl) {
        return;
      }
      if (
        event.data.type === "SSO_CALLBACK_SUCCESS" &&
        !isApiCallInProgress.current
      ) {
        isApiCallInProgress.current = true;
        const receivedData = event.data.payload;
        const values = getValues();
        dispatch(
          getSSOCallbackActionCreator({
            code: receivedData.code,
            state: receivedData.state,
            mail_id: values.email,
          }),
        )
          .then((response) => {
            isApiCallInProgress.current = false;
            setAccessToken(response.payload?.data?.access_token);
            // setCredMessage({ status: "", message: "" });
            // if (response?.payload?.data?.mfa_enabled) {
            //   setShowMFA(true);
            // } else {
            //   const currentValues = getValues();
            //   const data = {
            //     email: currentValues.email,
            //   };

            //   dispatch(registerMFAActionCreator(data)).then((response) => {
            //     if (response) {
            //       setShowMFA(true);
            //       setQrCode(response?.payload?.data?.mfa_uri);
            //     }
            //   });
            // }
            if (response.payload?.data?.access_token) {
              dispatch(getUserDetailActionCreator()).then((response) => {
                if (response) {
                  reset();
                  navigate("/budgets");
                }
              });
            }
          })
          .catch(() => {
            isApiCallInProgress.current = false;
            setCredMessage({
              status: "error",
              message: "Provided mail does not match SSO email.",
            });
          });
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showMFA) {
      const timer = setTimeout(() => {
        setMFAError(false);
        setDisableMFA(false);
        setOtpLockMsg(null);
        reset({
          email: "",
          // password: "",
          mfaCode: "",
        });
        setShowMFA(false);
        Cookies.remove("user");
        Cookies.remove("token");
        // setIsPageExpire(true);
        // sessionAlert();
        setCredMessage({
          status: "error",
          message: "Session expired. Please try again.",
        });
      }, sessionTimeout);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, sessionTimeout, showMFA]);

  useEffect(() => {
    if (disableMFA) {
      const restrictMFA = setTimeout(() => {
        setDisableMFA(false);
        setOtpLockMsg(null);
        setMFAError(false);
      }, 180000);
      return () => clearTimeout(restrictMFA);
    }
  }, [disableMFA]);

  // const sessionAlert = () => {
  //   toast.error(
  //     <ToastContent
  //       title="Error"
  //       description={"Your session has expired due to inactivity"}
  //     />,
  //     {
  //       position: "top-center",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: false,
  //       draggable: true,
  //       progress: undefined,
  //       icon: () => <img src={Error} />,
  //     },
  //   );
  // };

  const getLoginData = (data) => {
    dispatch(getSSOLoginActionCreator(data)).then((response) => {
      if (response?.payload?.data?.redirect_url) {
        setRedirectUrl(response.payload.data.redirect_url);
        setCredMessage({
          status: "success",
          message: "Email confirmed. Continue with login.",
        });
      } else if (response.error) {
        let errorMessage = "";
        if (response.payload.email) {
          errorMessage = response.payload.email;
        } else if (response.payload.mfa) {
          errorMessage = response.payload.mfa;
        } else {
          errorMessage = response.error.message;
        }

        if (errorMessage.includes("403")) {
          setCredMessage({
            status: "error",
            message: errorMessage.includes("inactive")
              ? "The provided email address is inactive. Please contact the admin."
              : errorMessage.includes("failed attempts")
                ? "Account temporarily locked due to multiple failed attempts. Please try after some time."
                : "The provided email address does not match any existing accounts in the Budget Tool.", //"Email is not authorized or the account is inactive."
          });
        } else {
          setCredMessage({
            status: "error",
            message: errorMessage.includes("inactive")
              ? "The provided email address is inactive. Please contact the admin."
              : errorMessage.includes("failed attempts")
                ? "Account temporarily locked due to multiple failed attempts. Please try after some time."
                : "The provided email address does not match any existing accounts in the Budget Tool.", //"Email is not authorized or the account is inactive."
          });
        }
      }
    });
  };

  const verifyMFA = (data) => {
    const updatedData = {
      ...data,
      body: {
        ...data.body,
        access: accessToken,
      },
    };

    dispatch(verifyMFAActionCreator(updatedData)).then((response) => {
      if (response?.payload?.data?.access_token) {
        if (response?.payload?.data?.is_new) {
          Cookies.set("skip_alert", false);
        }
        dispatch(getUserDetailActionCreator()).then((response) => {
          if (response) {
            reset();
            navigate("/budgets");
          }
        });
        setMFAError(false);
        if (otpLockMsg) {
          setOtpLockMsg(null);
        }
      } else if (response.error.message.includes("429")) {
        setOtpLockMsg(
          "Account temporarily locked due to multiple failed attempts. Please try after some time.",
        );
        resetField("mfaCode");
        setMFAError(true);
        setDisableMFA(true);
      } else {
        setMFAError(true);
        setOtpLockMsg(null);
      }
    });
  };

  const handleNextButtonClick = () => {
    if (ssoTimeout) {
      clearTimeout(ssoTimeout);
    }
    if (!showMFA) {
      const timeout = setTimeout(() => {
        setCredMessage({
          status: "error",
          message: "Session expired. Please try again.",
        });
        reset({
          email: "",
          mfaCode: "",
        });
        setRedirectUrl("");
      }, 300000);

      setSsoTimeout(timeout);
    }
  };

  const onSubmit = (data) => {
    //email validation
    if (data?.email) {
      const emailValue = data.email;

      // Custom validations
      const localPart = emailValue.split("@")[0];
      const domainPart = emailValue.split("@")[1];

      if (!localPart || !domainPart) {
        setError("email", {
          type: "manual",
          message: "Enter a valid email address",
        });
        return;
      }

      const isValidLocalPart =
        /^[a-zA-Z0-9]+([._%+'-][a-zA-Z0-9]+)*$/.test(localPart) &&
        !/^\./.test(localPart) && // Doesn't start with a dot
        !/\.$/.test(localPart) && // Doesn't end with a dot
        !/\.\./.test(localPart); // No consecutive dots

      if (!isValidLocalPart) {
        setError("email", {
          type: "manual",
          message: "Enter a valid email address",
        });
        return;
      }

      const isValidDomainPart =
        /^[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(domainPart) &&
        !/\.\./.test(domainPart); // No consecutive dots

      if (!isValidDomainPart) {
        setError("email", {
          type: "manual",
          message: "Enter a valid email address",
        });
        return;
      }

      if (/\s/.test(emailValue)) {
        setError("email", {
          type: "manual",
          message: "Email cannot contain spaces",
        });
        return;
      }
      if (
        !/^[a-zA-Z0-9]+([._%+'-][a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+$/.test(
          emailValue,
        )
      ) {
        setError("email", {
          type: "manual",
          message: "Enter a valid email address",
        });
        return;
      }
    }
    //email validation

    if (showMFA && data?.mfaCode) {
      let isLetterExist = /^[0-9]+$/;
      if (data?.mfaCode.length > 6) {
        setError("mfaCode", {
          type: "manual",
          message: "Maximum 6 digits required",
        });
        return;
      } else if (data?.mfaCode.length < 6) {
        setError("mfaCode", {
          type: "manual",
          message: "Minimum 6 digits required",
        });
        return;
      } else if (!isLetterExist.test(data?.mfaCode)) {
        setError("mfaCode", {
          type: "manual",
          message: "Please enter a valid OTP containing numbers only.",
        });
        return;
      }
    }
    // setIsPageExpire(false);
    if (showMFA) {
      verifyMFA({
        body: {
          email: data.email,
          otp_code: data.mfaCode,
          // remember_me: rememberMe,
        },
      });
    } else {
      getLoginData({
        body: { email: data.email },
      });
      handleNextButtonClick();
    }
  };

  const onEmailValidation = (e, field) => {
    field.onChange(e);
    setCredMessage({ status: "", message: "" });
    if (credMessage.status === "success") {
      setCredMessage({ status: "", message: "" });
      setRedirectUrl("");
    }
  };

  const openPopup = () => {
    const values = getValues();
    const url = `${redirectUrl}&login_hint=${values.email}`;
    // window.open(url, "OAuthPopup", "width=600,height=600");
    const popup = window.open(url, "OAuthPopup", "width=600,height=600");
    if (popup) {
      // Set a delay to inject the loading content into the popup after it's loaded
      setTimeout(() => {
        // Only modify the popup content if it's still open
        if (!popup.closed) {
          popup.document.body.innerHTML = `
          <html>
            <head><title>OAuth Login</title></head>
            <body>
              <h2>Redirecting to Microsoft login...</h2>
              <p>Please wait while we redirect you to login.</p>
            </body>
          </html>
        `;

          // Optionally add a CSS for the loading spinner
          popup.document.head.insertAdjacentHTML(
            "beforeend",
            `
          <style>
             body{
              text-align:center;
              padding: 50px;
              display:flex;
              flex-direction: column;
              justify-content:center;
             }

            .loader {
              border: 16px solid #f3f3f3;
              border-top: 16px solid #3498db;
              border-radius: 50%;
              width: 50px;
              height: 50px;
              margin-left:25%;
              animation: spin 2s linear infinite;
            }

            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          </style>
        `,
          );
        }
      }, 500);
    }

    const checkPopupClosed = setInterval(() => {
      if (popup.closed) {
        clearInterval(checkPopupClosed);
        // Handle next steps when the popup is closed (e.g., redirect or fetch the code)
      }
    }, 1000);
  };

  useEffect(() => {
    return () => {
      if (ssoTimeout) {
        clearTimeout(ssoTimeout);
      }
      if (showMFA) {
        clearTimeout(ssoTimeout);
        setSsoTimeout(null); //  reset state to avoid accidental reuse
      }
    };
  }, [ssoTimeout, showMFA]);

  return (
    <div
      className={`${style.logIn} ${showMFA && !!qrCode ? style.qrHeight : style.regularHeight}`}
    >
      <h1 className="page-title">Login</h1>
      <div className={style.innerContent}>
        <LeftImageBlock />
        <div className={style.formSection} style={{ marginTop: "23vh" }}>
          {/* showMFA && !!qrCode ? { marginTop: "12vh" } :  */}
          <div>
            <div className={style.logoContainer}>
              <img src={logo} alt="hines logo" className={style.brandLogo} />
            </div>
            <div>
              {showMFA && !!qrCode && (
                <div className={style.qrCodeImage}>
                  <p className={style.qrCodeText}>
                    Scan the QR code to link your account with the Authenticator
                    app.
                  </p>
                  <div role="img" aria-label="QR code">
                    <QRCodeSVG value={qrCode} size={130} title="QR code" />
                  </div>
                </div>
              )}
              <p className={style.errMsg}>
                {showMFA
                  ? "Enter the 6-digit verification code generated by your Authenticator app."
                  : "Welcome to the Hines Automated Marketing Budgeting Tool for Property Marketing! Please sign in with SSO below (using your @myhines.com email)."}
              </p>
              <form
                onSubmit={handleSubmit(onSubmit)}
                noValidate
                className={showMFA && !!qrCode ? style.qrForm : style.formwrap}
              >
                {!showMFA && (
                  <>
                    <div className={style.fieldContainer}>
                      <Controller
                        control={control}
                        name="email"
                        rules={{
                          required: "Email is required",
                          // pattern: {
                          //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          //   message: "Please enter a valid email address",
                          // },
                          /* pattern: {
                            value:
                              /^[^\s@][a-zA-Z0-9._%+-]*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Enter a valid email address",
                          },
                          validate: {
                            noSpaces: (value) =>
                              !/\s/.test(value) ||
                              "Email cannot contain spaces",
                            noSpecialChars: (value) =>
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+$/.test(
                                value,
                              ) ||
                              "Email cannot start or end with special characters",
                            validDomain: (value) => {
                              const [localPart, domainPart] = value.split("@");
                              return (
                                (/^[a-zA-Z0-9]+([._%+-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*\.[a-zA-Z]{2,}$/.test(
                                  value,
                                ) &&
                                  !/\.\./.test(localPart) &&
                                  !/^\./.test(localPart) &&
                                  !/\.$/.test(localPart) &&
                                  !/\.\./.test(domainPart)) ||
                                "Enter a valid email address"
                              );
                            },
                          },*/
                        }}
                        render={({ field }) => (
                          <InputFields
                            {...field}
                            type="email"
                            label="Email"
                            name="email"
                            placeholder="Enter email"
                            errors={errors.email}
                            title="Enter your email"
                            onChange={(e) => {
                              onEmailValidation(e, field);
                            }}
                          />
                        )}
                      />
                      {/* <button onClick={openPopup}>Open OAuth Popup</button> */}
                    </div>
                    {/* <div className={style.fieldContainer}>
                      <Controller
                        control={control}
                        name="password"
                        rules={{ required: "Password is required" }}
                        render={({ field }) => (
                          <InputFields
                            {...field}
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            placeholder="Enter password"
                            errors={errors.password}
                            showPasswordToggle={true}
                            onTogglePassword={() =>
                              setShowPassword(!showPassword)
                            }
                            showPassword={showPassword}
                            title="Enter the password"
                          />
                        )}
                      />
                    </div> */}
                    {/* {!(isPageExpire || location?.state?.message) &&
                    loginErr &&
                    !showMFA ? (
                      <p style={{ color: "#B70021" }} className={style.errMsg}>
                        Incorrect username or password
                      </p>
                    ) : (
                      <></>
                    )} */}
                    {/* <div className={style.checkboxRow}>
                      <input
                        type="checkbox"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        className={style.checkbox}
                      />
                      <label
                        htmlFor="rememberMe"
                        className={style.checkboxLabel}
                      >
                        Remember Me
                      </label>
                    </div> */}
                  </>
                )}
                {/* <OauthPopup
                  url={redirectUrl}
                  onCode={onCode}
                  onClose={onClose}
                >
                <button>Open OAuth Popup</button>
                </OauthPopup> */}
                {showMFA && (
                  <Controller
                    control={control}
                    name="mfaCode"
                    rules={{
                      required: "Verification code is required",
                    }}
                    render={({ field }) => (
                      <InputFields
                        {...field}
                        type="text"
                        label="Verification Code"
                        name="mfaCode"
                        placeholder="Enter code"
                        errors={errors.mfaCode}
                        onChange={(e) => {
                          field.onChange(e);
                          setMFAError(false);
                        }}
                        title="Enter the verification code"
                        isDisabled={disableMFA}
                      />
                    )}
                  />
                )}
                {mfaError && showMFA && !errors?.mfaCode && (
                  <p style={{ color: "red" }} className={style.mfaErrMsg}>
                    {otpLockMsg ? otpLockMsg : <>Invalid MFA code</>}
                  </p>
                )}
                {credMessage.status !== "success" && (
                  <div
                    className={`${showMFA ? style.verifyBtn : ""} ${style.signInBtn}`}
                  >
                    <Button
                      text={showMFA ? "Verify" : "Next"}
                      loader={
                        credMessage.status !== "success" && isLoading
                          ? true
                          : false
                      }
                      disable={disableMFA}
                    />
                  </div>
                )}
                {credMessage.status === "error" && !errors?.email && (
                  <p
                    style={{ color: "red", marginTop: "14px" }}
                    className={style.credErrMsg}
                  >
                    {credMessage.message}
                  </p>
                )}
              </form>
              {credMessage.status === "success" && (
                <div className={style.SsoBtn}>
                  {/* style={{ marginTop: "10px" }} */}
                  <Button
                    text="Login using SSO"
                    loader={isLoading}
                    clickFunction={() => openPopup()}
                  />
                </div>
              )}
              {credMessage.status === "success" && (
                <p
                  style={{
                    color: "green",
                    marginTop: "8px",
                    marginBottom: "8px",
                  }}
                  className={style.credMessage}
                >
                  {credMessage.message}
                </p>
              )}
              <div className={style.helpSection}>
                <p className={style.helpText}>Need help or require access?</p>
                <p className={style.contactLink}>
                  Contact{" "}
                  <a
                    href="mailto:AmericasDigitalMarketing@hines.com"
                    className={style.helpEmail}
                  >
                    AmericasDigitalMarketing@hines.com
                  </a>
                </p>
                {/* <p className={style.contactLink}>
                  If you require access, please contact us.{" "}
                  <a
                    href="https://www.hines.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Hines
                  </a>
                </p> */}
                <p className={style.hinesText}>© 2025 Hines</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
