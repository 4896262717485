import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";
import { saveAs } from "file-saver";
import { logoutActionCreator } from "./LoginSlices";

const initialState = {
  allBudgetList: [],
  budgetList: [],
  productGroups: [],
  productList: [],
  budgetDetails: null,
  loading: false,
  btnLoader: false,
  status: "",
  statusCode: null,
  error: "",
};

export const getBudgetListActionCreator = createAsyncThunk(
  "budget/list",
  async () => {
    const API_URL = `${SERVER_URL.BUDGET_LIST}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const getBudgetDetailActionCreator = createAsyncThunk(
  "budget/detail",
  async (budgetId, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.BUDGET_DETAIL}${budgetId}/`;
      const { data } = await fetchUtility("get", API_URL, null, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createBudgetActionCreator = createAsyncThunk(
  "budget/create",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_BUDGET}`;
    try {
      const { data } = await fetchUtility("post", API_URL, params.body, true, {
        successToast: true,
        errorToast: false,
      });
      return data;
    } catch (error) {
      const errors = {
        budget_type: error.response?.data?.errors?.budget_type?.[0] || null,
        budget_status: error.response?.data?.errors?.budget_status?.[0] || null,
        budget_name: error.response?.data?.errors?.budget_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateBudgetAction = createAsyncThunk(
  "budget/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.UPDATE_BUDGET}`;
      const { data } = await fetchUtility(
        "patch",
        API_URL,
        [
          {
            ...params.body,
            budget_id: params.budget_id,
          },
        ],
        true,
        params?.toast
          ? params.toast
          : { successToast: false, errorToast: false },
      );
      return data;
    } catch (error) {
      const errors = {
        budget_status:
          error.response?.data?.errors?.errors?.[0]?.errors?.budget_status ||
          null,
        budget_name:
          error.response?.data?.errors?.errors?.[0]?.errors?.budget_name ||
          null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const getProductGroupActionCreator = createAsyncThunk(
  "budget/productgroup",
  async (params) => {
    const API_URL = `${SERVER_URL.BUDGET_CYCLE}/${params.year}/`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const getProductsByGroupCreator = createAsyncThunk(
  "budget/products",
  async (params) => {
    const API_URL = `${SERVER_URL.BUDGET_CYCLE}/${params.year}/`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const deleteBudgetAction = createAsyncThunk(
  "budget/delete",
  async (params) => {
    let API_URL = `${SERVER_URL.DELETE_BUDGET}`;
    const { data } = await fetchUtility("delete", `${API_URL}`, params, true, {
      successToast: false,
      errorToast: true,
    });
    return data;
  },
);

export const completeBudgetAction = createAsyncThunk(
  "budget/complete",
  async (params) => {
    const API_URL = `${SERVER_URL.COMPLETE_BUDGET}`;
    const budgetId = params.budgetId;
    const { data } = await fetchUtility(
      "post",
      `${API_URL}${budgetId}/`,
      params.products,
      true,
      {
        successToast: false,
        errorToast: false,
      },
    );
    return data;
  },
);

export const getBudgetProductsActionCreator = createAsyncThunk(
  "budget/products",
  async (budgetCycle) => {
    const API_URL = `${SERVER_URL.BUDGET_PRODUCTS}?budget_cycle=${budgetCycle}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const addBudgetProductActionCreator = createAsyncThunk(
  "budget/addproduct",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.BUDGET_ADD_PRODUCT}`;
      const { data } = await fetchUtility("post", API_URL, params, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      if (error.response?.data?.errors) {
        return rejectWithValue(error.response.data);
      }
    }
  },
);

export const editBudgetProductActionCreator = createAsyncThunk(
  "budget/editproduct",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.EDIT_BUDGET_PRODUCT}`;
      const { data } = await fetchUtility("patch", API_URL, params.body, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      if (error.response?.data?.errors) {
        return rejectWithValue(error.response.data);
      }
    }
  },
);

export const getProductDetailsByBudgetYear = createAsyncThunk(
  "budget/productdetailsbudgetyear",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.PRODUCT_DETAILS_BY_BUDGET_YEAR}?product_id=${params.productId}&budget_year=${params.budgetYear}`;
      const { data } = await fetchUtility("get", API_URL, params, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      const errorResponse = error.response?.data;
      const errors = {
        product: errorResponse?.errors?.error?.[0],
      };
      return rejectWithValue(errors);
    }
  },
);

export const deleteBudgetProductActionCreator = createAsyncThunk(
  "budget/deleteproduct",
  async (params) => {
    try {
      const API_URL = `${SERVER_URL.DELETE_BUDGET_PRODUCT}`;
      const { data } = await fetchUtility("delete", API_URL, params, true, {
        successToast: false,
        errorToast: false,
      });
      return data;
    } catch (error) {
      throw new Error(error.response?.data.message);
    }
  },
);

export const getBudgetProductDetailActionCreator = createAsyncThunk(
  "budget/productdetail",
  async (budgetId) => {
    const API_URL = `${SERVER_URL.BUDGET_PRODUCT_DETAIL}${budgetId}/`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const exportBudgetsActionCreator = createAsyncThunk(
  "budget/export/",
  async () => {
    const API_URL = `${SERVER_URL.EXPORT_BUDGET_LIST}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: true,
      errorToast: true,
    });
    const blob = new Blob([data], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "budget_export.csv"; // Name of the file to be downloaded
    link.click();
    return data;
  },
);

export const exportBudgetDetailCreator = createAsyncThunk(
  "budget/export-detail/",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.EXPORT_BUDGET_DETAIL}${params.budgetId}/?notes=${params.notes}`;
    try {
      const response = await fetchUtility("get", API_URL, null, true, {
        successToast: true,
        errorToast: true,
        responseType: "blob",
      });
      const { data } = response;
      const filename = `${params.budgetName}.xlsx`;
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      saveAs(blob, filename);

      return { success: true };
    } catch (error) {
      console.error("Export error:", error);
      return rejectWithValue(error);
    }
  },
);

export const getAllBudgetActionCreator = createAsyncThunk(
  "budget/all",
  async () => {
    const API_URL = `${SERVER_URL.BUDGET_ALL}`;
    const { data } = await fetchUtility("get", API_URL, null, true, {
      successToast: false,
      errorToast: false,
    });
    return data;
  },
);

export const clearMonthSelectionAction = createAsyncThunk(
  "budget/clear-month",
  async (param) => {
    const API_URL = `${SERVER_URL.BUDGET_DETAIL}${param.id}/clear-months/`;
    const { data } = await fetchUtility("patch", API_URL, param, true, {
      successToast: true,
      errorToast: false,
    });
    return data;
  },
);

const budgetSlice = createSlice({
  name: "budget",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBudgetListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBudgetListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.budgetList = action.payload?.data;
      state.budgetDetails = null;
      state.error = "";
    });
    builder.addCase(getBudgetListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //get product groups
    builder.addCase(getProductGroupActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProductGroupActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.productGroups = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getProductGroupActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //get budget details
    builder.addCase(getBudgetDetailActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBudgetDetailActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.budgetDetails = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getBudgetDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    //create budget
    builder.addCase(createBudgetActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createBudgetActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(createBudgetActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
    //update budget
    builder.addCase(updateBudgetAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateBudgetAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(updateBudgetAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });
    //delete budget
    builder.addCase(deleteBudgetAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteBudgetAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(deleteBudgetAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    //add budget product
    builder.addCase(addBudgetProductActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(addBudgetProductActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(addBudgetProductActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    //edit budget product
    builder.addCase(editBudgetProductActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(editBudgetProductActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(
      editBudgetProductActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.payload;
      },
    );

    //delete budget product
    builder.addCase(deleteBudgetProductActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteBudgetProductActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(
      deleteBudgetProductActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.payload;
      },
    );

    builder.addCase(completeBudgetAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(completeBudgetAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(completeBudgetAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    builder.addCase(clearMonthSelectionAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(clearMonthSelectionAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(clearMonthSelectionAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    //Export budgets
    builder.addCase(exportBudgetsActionCreator.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(exportBudgetsActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(exportBudgetsActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    //Export budget detail
    builder.addCase(exportBudgetDetailCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(exportBudgetDetailCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(exportBudgetDetailCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    //print all budget
    builder.addCase(getAllBudgetActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllBudgetActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.allBudgetList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getAllBudgetActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });

    builder.addCase(getBudgetProductsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBudgetProductsActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(
      getBudgetProductsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.payload;
      },
    );

    //get product details by budget year
    builder.addCase(getProductDetailsByBudgetYear.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getProductDetailsByBudgetYear.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(getProductDetailsByBudgetYear.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.payload;
    });

    //clear state on logout
    builder.addCase(logoutActionCreator.fulfilled, (state) => {
      state.budgetList = [];
      state.allBudgetList = [];
    });
    builder.addCase(logoutActionCreator.rejected, (state) => {
      state.budgetList = [];
      state.allBudgetList = [];
    });
  },
});

export default budgetSlice.reducer;
