import { lazy } from "react";
import Login from "./pages/auth/login/Login";
import SSOCallback from "./pages/auth/sso/SSOCallback";

const Dashboard = lazy(() => import("./pages/dashboard/Dashboard"));
const UserList = lazy(() => import("./pages/users/UserList"));
const ChangePassword = lazy(
  () => import("./pages/auth/changePassword/ChangePassword"),
);
const AccessDenied = lazy(
  () => import("./pages/auth/access-denied/AccessDenied"),
);
const ForgotPassword = lazy(
  () => import("./pages/auth/forgot-password/ForgotPassword"),
);
const PropertyList = lazy(() => import("./pages/property/PropertyList"));
const UserProfile = lazy(() => import("./pages/profile/UserProfile"));
const CategoryList = lazy(() => import("./pages/category/Category"));
const VendorList = lazy(() => import("./pages/source-vendor/Vendor"));
const ProductList = lazy(() => import("./pages/product/ProductList"));
const SubCategory = lazy(() => import("./pages/sub-category/SubCategory"));
const BudgetList = lazy(() => import("./pages/budget/Budget"));
const BudgetDetail = lazy(() => import("./pages/budget/BudgetDetail"));

export const routes = [
  {
    path: "/users",
    name: "Users",
    component: <UserList />,
    allowedRoles: true,
  },
  {
    path: "/category",
    name: "Category",
    component: <CategoryList />,
    allowedRoles: true,
  },
  {
    path: "/vendor",
    name: "Vendor",
    component: <VendorList />,
    allowedRoles: true,
  },
  {
    path: "/product-groups",
    name: "SubCategory",
    component: <SubCategory />,
    allowedRoles: true,
  },
];
export const commonRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
  },
  // {
  //   path: "/",
  //   name: "",
  //   component: <ProductList />,
  // },
  {
    path: "/access-denied",
    name: "AccessDenied",
    component: <AccessDenied />,
  },
  {
    path: "/profile",
    name: "profile",
    component: <UserProfile />,
  },
  {
    path: "/properties",
    name: "properties",
    component: <PropertyList />,
  },
  {
    path: "/budget-detail/:id",
    name: "BudgetDetail",
    component: <BudgetDetail />,
  },
  {
    path: "/budgets",
    name: "Budget",
    component: <BudgetList />,
  },
  {
    path: "/products",
    name: "products",
    component: <ProductList />,
  },
];
export const authroutes = [
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    isLogged: false,
  },
  {
    path: "/forgot-password",
    name: "",
    component: <ForgotPassword />,
    isLogged: false,
  },
  {
    path: "/update-password",
    name: "ChangePassword",
    component: <ChangePassword />,
    isLogged: true,
  },
  {
    path: "/sso/callback/*",
    name: "ssoCallback",
    component: <SSOCallback />,
    isLogged: false,
  },
];
