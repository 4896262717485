import { useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Success from "../../assets/icons/success.svg";
import Error from "../../assets/icons/error.svg";
import Toast from "../toast/Toast";
import ToastContent from "../toast/ToastContent";
import {
  logoutActionCreator,
  getUserDetailActionCreator,
} from "../../redux/slices/LoginSlices";
import Cookies from "js-cookie";

function InjectAxiosInterceptors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearState = () => {
    navigate(location.pathname, { replace: true, state: null });
  };

  const fetchUser = async () => {
    await dispatch(getUserDetailActionCreator(Cookies.get("token")));
  };

  // Add storage event listener for cross-tab logout
  useEffect(() => {
    const handleStorageEvent = (e) => {
      if (e.key === "logout-event") {
        // Another tab logged out, perform logout in this tab
        dispatch(
          logoutActionCreator({
            body: {},
            toast: { successToast: false, errorToast: false },
          }),
        ).then(() => {
          navigate("/login", {
            state: { message: "You have been logged out in another tab" },
          });
        });
      }
    };

    window.addEventListener("storage", handleStorageEvent);
    return () => {
      window.removeEventListener("storage", handleStorageEvent);
    };
  }, [dispatch, navigate]);

  useEffect(() => {
    clearState();
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response.config.method.toUpperCase() !== "GET") {
          let toastrMsg = "";
          if (response.config.method.toUpperCase() === "PUT") {
            toastrMsg = response.data.detail;
          } else if (response.config.method.toUpperCase() === "DELETE") {
            toastrMsg = "Deleted Successfully";
          } else {
            toastrMsg = response.data?.message
              ? response.data.message
              : "Success";
          }
          if (response?.config?.toastrs?.successToast) {
            toast.success(
              <ToastContent
                title="Success"
                description={
                  response.request.responseURL.includes("/login")
                    ? "Login Successful!"
                    : toastrMsg
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Success} />,
              },
            );
          }
        }
        return response;
      },
      (errorRes) => {
        if (errorRes?.code == "ERR_NETWORK") {
          toast.error(
            <ToastContent
              title="Error"
              description={
                "Network connection lost. Please check your connection and try again"
              }
            />,
            {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
              progress: undefined,
              icon: () => <img src={Error} />,
            },
          );
        } else {
          let errorMessage = "Something went wrong. Please try again later.";
          // Redirect to session expired page on 401 errors
          const sessionExpire = {
            state: {
              message: "Your session has expired. Please try login",
            },
          };

          if (errorRes.response?.status === 403) {
            fetchUser();
            if (
              errorRes?.response?.config?.headers?.url &&
              !errorRes?.response?.config?.headers?.url?.includes(
                "/users/sso/login",
              )
            ) {
              toast.error(
                <ToastContent
                  title="Error"
                  description={
                    "You don't have permission to perform this action"
                  }
                />,
                {
                  position: "top-center",
                  autoClose: 5000,
                  toastId: "error-toast",
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  icon: () => <img src={Error} />,
                },
              );
            }
          }

          if (
            errorRes.response?.status === 401 &&
            errorRes?.response?.data?.code == "user_inactive" &&
            !errorRes?.config?.url.includes("/mfa/status/")
          ) {
            dispatch(logoutActionCreator())
              .then(() => {
                navigate("/login", sessionExpire);
              })
              .catch(() => {
                navigate("/login", sessionExpire);
              });
            toast.error(
              <ToastContent
                title="Error"
                description={
                  "Your account has been deactivated. Please contact the administrator for further assistance"
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                toastId: "error-toast",
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
            return;
          }
          if (
            errorRes.response?.status === 401 &&
            errorRes?.response?.data?.code == "user_not_found" &&
            !errorRes?.config?.url.includes("/mfa/status/")
          ) {
            dispatch(logoutActionCreator())
              .then(() => {
                navigate("/login", sessionExpire);
              })
              .catch(() => {
                navigate("/login", sessionExpire);
              });
            toast.error(
              <ToastContent
                title="Error"
                description={
                  "Your account has been deleted. Please contact the administrator for further details"
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                toastId: "error-toast",
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
            return;
          }
          if (
            errorRes.response?.status === 401 &&
            (errorRes?.response?.data?.code == "token_not_valid" ||
              errorRes?.code == "ERR_BAD_REQUEST") &&
            !errorRes?.config?.url.includes("/mfa/status/")
          ) {
            dispatch(logoutActionCreator())
              .then(() => {
                navigate("/login", sessionExpire);
              })
              .catch(() => {
                navigate("/login", sessionExpire);
              });
            toast.error(
              <ToastContent
                title="Error"
                description={"Your session has expired. Please try login"}
              />,
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
            return;
          }
          // Don't show error toasts on login page
          if (errorRes?.config?.toastrs?.errorToast) {
            if (errorRes.response?.data?.message) {
              errorMessage = errorRes.response.data.message;

              // If the message is an object, get the first error message
              if (typeof errorMessage === "object") {
                errorMessage = errorMessage[Object.keys(errorMessage)[0]];
              }
            } else if (
              errorRes.response?.data &&
              typeof errorRes.response?.data === "object"
            ) {
              errorMessage = errorRes.response.data;
              errorMessage = errorMessage[Object.keys(errorMessage)[0]];
            }
          }
          if (
            errorRes?.response?.data?.code !== "token_not_valid" &&
            errorRes?.config?.toastrs?.errorToast
          ) {
            // navigate("/login", sessionExpire);
            fetchUser();
            toast.error(
              <ToastContent
                title="Error"
                description={
                  errorRes.response?.status === 401
                    ? "Your session has expired due to inactivity"
                    : errorMessage && errorMessage.toLowerCase() !== "error"
                      ? errorMessage
                      : "Something went wrong. Please try again later."
                }
              />,
              {
                position: "top-center",
                autoClose: 5000,
                toastId: "error-toast",
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                icon: () => <img src={Error} />,
              },
            );
          }
        }
        return Promise.reject(errorRes);
      },
    );

    // Cleanup function to eject the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dispatch]);

  return <Toast />;
}

export default InjectAxiosInterceptors;
