import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SERVER_URL from "../../constants/constants";
import fetchUtility from "../fetchUtility";

const initialState = {
  subCategoryList: [],
  btnLoader: false,
  loading: false,
  status: "",
  error: "",
};

export const getSubCategoryListAction = createAsyncThunk(
  "subcategory/list",
  async () => {
    let API_URL = `${SERVER_URL.SUB_CATEGORY}`;
    const { data } = await fetchUtility("get", `${API_URL}`);
    return data;
  },
);

export const saveSubCategoryAction = createAsyncThunk(
  "subcategory/save",
  async (params, { rejectWithValue }) => {
    const API_URL = `${SERVER_URL.CREATE_SUB_CATEGORY}`;

    try {
      const { data } = await fetchUtility(
        "post",
        `${API_URL}`,
        params.body,
        true,
        { successToast: true, errorToast: false },
      );
      return data; // If the request is successful, return the data
    } catch (error) {
      const errors = {
        subcategory_name:
          error.response?.data?.errors?.subcategory_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const updateSubCategoryAction = createAsyncThunk(
  "subcategory/update",
  async (params, { rejectWithValue }) => {
    try {
      const API_URL = `${SERVER_URL.SUB_CATEGORY}`;
      const { data } = await fetchUtility(
        "patch",
        `${API_URL}${params.body.product_group_id}/`,
        params.body,
        true,
        params.toast ? params.toast : { successToast: true, errorToast: false },
      );
      return data;
    } catch (error) {
      const errors = {
        subcategory_name:
          error.response?.data?.errors?.subcategory_name?.[0] || null,
      };
      return rejectWithValue(errors);
    }
  },
);

export const deleteSubCategoryAction = createAsyncThunk(
  "subcategory/delete",
  async (id) => {
    let API_URL = `${SERVER_URL.SUB_CATEGORY}`;
    const { data } = await fetchUtility(
      "delete",
      `${API_URL}${id}/`,
      null,
      true,
      { successToast: false, errorToast: true },
    );
    return data;
  },
);

const subCategorySlice = createSlice({
  name: "subcategory",
  initialState,
  reducers: {
    setBtnLoaderFalse: (state) => {
      state.btnLoader = false;
    },
  },
  extraReducers: (builder) => {
    //category List
    builder.addCase(getSubCategoryListAction.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSubCategoryListAction.fulfilled, (state, action) => {
      state.loading = false;
      state.subCategoryList = action.payload?.data;
      state.error = "";
    });
    builder.addCase(getSubCategoryListAction.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    //create new category
    builder.addCase(saveSubCategoryAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveSubCategoryAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(saveSubCategoryAction.rejected, (state, action) => {
      state.btnLoader = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //update category
    builder.addCase(updateSubCategoryAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateSubCategoryAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(updateSubCategoryAction.rejected, (state, action) => {
      state.btnLoader = false;
      // state.error = action.error.message;
      state.error = action.payload;
    });
    //delete category
    builder.addCase(deleteSubCategoryAction.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteSubCategoryAction.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = "";
    });
    builder.addCase(deleteSubCategoryAction.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  },
});
export default subCategorySlice.reducer;
export const { setBtnLoaderFalse } = subCategorySlice.actions;
