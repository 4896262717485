import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "./index.scss";

// Function to render the application
const renderApp = () => {
  try {
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
      <React.StrictMode>
        <Provider store={store}>
          <Router>
            <App />
          </Router>
        </Provider>
      </React.StrictMode>,
    );
  } catch (error) {
    console.error("Chunk loading failed, reloading the app...", error);

    // If a chunk fails to load, reload the page after 1 second
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
};

// Handle chunk errors globally
window.addEventListener("error", (event) => {
  if (event.message && event.message.includes("Loading chunk")) {
    console.error("Chunk failed to load. Reloading the page...");
    window.location.reload();
  }
});

// Render the app
renderApp();

// Start performance monitoring
reportWebVitals();
